import React, { useState, useEffect } from 'react';
import { styled } from '../../../stitches.config';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Box from '../Box';

// icons 
import ArrowLeft from '../../../assets/img/mamadu/carrefour/ArrowLeft.png'
import ArrowRight from '../../../assets/img/mamadu/carrefour/ArrowRight.png'

// logo i background do wgrania 
// import backgroundDesktop from '../../../assets/img/natemat/minisite/MS-4-background-d.png';
// import backgroundMobile from '../../../assets/img/natemat/minisite/MS-4-background-m.png';
import logo from '../../../assets/img/natemat/minisite/MS-4-logo.png';
import text from '../../../assets/img/natemat/minisite/MS-4-text.png';

import leftDesktop from '../../../assets/img/natemat/minisite/MS-4-desktop-left.png';
import rightDesktop from '../../../assets/img/natemat/minisite/MS-4-desktop-right.png';
import leftMobile from '../../../assets/img/natemat/minisite/MS-4-mobile-left.png';
import rightMobile from '../../../assets/img/natemat/minisite/MS-4-mobile-right.png';

// strzałki do slidera

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <CustomArrow
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <img src={ArrowRight} />
        </CustomArrow>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <CustomArrow
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        >
            <img src={ArrowLeft} />
        </CustomArrow>
    );
}

// losowe ustawienie slidów

const shuffleCards = (number) => (cards) => {
    const array = Object.keys(cards).map(k => cards[k]);
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    const obj = array.reduce((acc, curr, i) => (acc[`b${i + number}`] = curr, acc), {})

    return obj
}

const shuffleCardsMobile = shuffleCards(1)

export default props => {
    const [mobileCards] = useState(() => shuffleCardsMobile([props.settings.b1, props.settings.b2, props.settings.b3, props.settings.b4]))
    const settings = {
        infinite: true,
        dots: true,
        arrows: true,
        speed: 500,
        // uwzględniać 3 lub 4 slidesToShow razem z poniższym komentarzem
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            /*{
                // uwzględniać ten breakpoint gdy mają być widoczne 4 slidy na dekstopie
                breakpoint: 1370,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    arrows: true,
                    dots: true,
                }
            },*/
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "45px",
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "70px",
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "50px",
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "30px",
                }
            },
            {
                breakpoint: 375,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: false,
                    infinite: true,
                    dots: true,
                    centerPadding: "20px",
                }
            }
        ]
    };
    return (
        <Section>
            <Wrapper>
                {/* Elementy tła */}
                <ColumnBackgroundWrapper>
                    <ColumnBackground>
                        <img className="column left mobile" src={leftMobile} />
                        <img className="column right mobile" src={rightMobile} />
                        <img className="column left desktop" src={leftDesktop} />
                        <img className="column right desktop" src={rightDesktop} />
                    </ColumnBackground>
                </ColumnBackgroundWrapper>
                <Logo>
                    <img className="logo" src={logo} />
                    <img className="text" src={text} />
                </Logo>
                {/*<BackgroundImageMobile>
                    <img src={backgroundM} />
                </BackgroundImageMobile>*/}
                {/*
                <CarouselWrapper>
                    <Slider {...settings}>
                        <Box {...props}
                            id="b1"
                            type="AMiniSite"
                            settings={props.settings.b1}
                        />
                        <Box {...props}
                            id="b2"
                            type="AMiniSite"
                            settings={props.settings.b2}
                        />
                        <Box {...props}
                            id="b3"
                            type="AMiniSite"
                            settings={props.settings.b3}
                        />
                    </Slider>
                </CarouselWrapper>
                */}
                <BoxWrapper>
                    <Box {...props}
                        id="b1"
                        type="AMiniSite"
                        settings={props.settings.b1}
                    />
                    <Box {...props}
                        id="b2"
                        type="AMiniSite"
                        settings={props.settings.b2}
                    />
                    <Box {...props}
                        id="b3"
                        type="AMiniSite"
                        settings={props.settings.b3}
                    />
                </BoxWrapper>
            </Wrapper>
        </Section>
    );
}

// slider

const CustomArrow = styled("div", {
    "img": {
        width: "43px",
        height: "43px",
        margin: "auto",
        position: "absolute",
        top: "-10px",
        left: "0"
    }
});

const CarouselWrapper = styled("div", {
    marginBottom: "55px",
    overflow: "visible",
    ".slick-list": {
        marginBottom: "50px",
        "@bp4": {
            marginBottom: "0px",
            padding: "10px 0px"
        }
    },
    ".slick-slide": {
        "& > div": {
            display: "grid",
            placeItems: "center"
        }
    },
    ".slick-arrow": {
        zIndex: 10
    },
    ".slick-prev": {
        left: "410px",
        top: "382px"
    },
    ".slick-next": {
        right: "440px",
        top: "382px"
    },
    ".slick-dots": {
        bottom: "-35px",
        "li": {
            margin: "0",
            width: "18px",
            height: "9px",
            "&.slick-active": {
                button: {
                    "&:before": {
                        /* main kolor minisite */
                        color: "#f2d5a6",
                        opacity: 1,
                        "@bp4": {
                            borderRadius: "10px"
                        }
                    }
                }
            },
            button: {
                width: "9px",
                height: "9px",
                padding: "0",
                "&:before": {
                    width: "9px",
                    height: "9px",
                    fontSize: "9px",
                    lineHeight: "9px",
                    color: "white",
                    opacity: 1,
                    "@bp4": {
                        borderWidth: "0px",
                        borderRadius: "10px"
                    }
                }
            }
        }
    }
});

const Section = styled("div", {
    overflow: "hidden",
    width: "100%",
    paddingBottom: "1px",
    // backgroundColor: "$grayscale0",
    // backgroundImage: `url(${backgroundMobile})`,
    backgroundColor: "#eadfcc",
    position: "relative",
    "@bp4": {
        // backgroundImage: `url(${background})`,
        height: "630px"
    },
    "@bp5": {
        height: "690px"
    },
    ".desktop": {
        display: "none",
        "@bp4": {
            display: "block"
        }
    },
    ".mobile": {
        "@bp4": {
            display: "none"
        }
    },
    "& .minisite-box": {
        minHeight: "320px",
        height: "100%",
        paddingBottom: "10px",
        "& img": {
            "@bp5": {
                width: "394px",
                height: "267px",
            }
        },
        "@bp5": {
            width: "400px",
            minHeight: "389px",
            height: "auto",
            paddingBottom: "10px",
        }
    }
});

const Wrapper = styled("div", {
    width: "100%",
    margin: "0 auto",
    paddingTop: "480px",
    paddingBottom: "60px",
    "@bp4": {
        paddingTop: "240px",
        paddingBottom: "0px",
        flexDirection: "row",
        maxWidth: "972px",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    /* ustawiać ten breakpoint gdy są 4 slidy na dekstopie 
    @media only screen and (min-width: 1370px) {
        max-width: 1266px;
    } */
    "@bp5": {
        maxWidth: "1280px"
    },
    "h1": {
        fontSize: "30px",
        lineHeight: "38px",
        color: "#D7141A",
        textAlign: "center",
        paddingTop: "10px",
        margin: "0",
        fontWeight: 800,
        fontFamily: "$grotesk",
        position: "relative",
        "@bp4": {
            marginBottom: "0px",
            fontSize: "34px",
            lineHeight: "42px"
        }
    },
    ".h1-bold": {
        color: "#242424",
        marginBottom: "0px",
        paddingTop: "10px",
        "@bp4": {
            paddingTop: "0",
            marginBottom: "185px"
        }
    },
});

const Logo = styled("div", {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    flexDirection: "column",
    top: "65px",
    left: "0",
    "@bp4": {
        top: "25px",
        flexDirection: "row",
        gap: "40px"
    },
    ".logo": {
        maxWidth: '180px',
        '@bp4':{
            maxWidth: '445px'
        }
    },
    ".text": {
        maxWidth: '330px'
    },
});

const BoxWrapper = styled("div", {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    gap: "24px",
    "@bp4": {
        flexDirection: "row",
        alignItems: "stretch"
    }
});

const ColumnBackgroundWrapper = styled('div', {
    position: 'absolute',
    inset: 0
});

const ColumnBackground = styled(Wrapper, {
    position: 'relative',
    height: '100%',
    background: 'unset',
    pointerEvents: 'none',
    padding: 0,
    '@mobile': {
        margin: '0 auto',
        maxWidth: '480px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    '@media (max-width: 480px)':{
        width: '480px',
        left: 'calc(50vw - 240px)'
    },
    '.column': {
        position: 'absolute',
        top: '-35px',
        '@bp4':{
            top: '50%'
        }
    },
    '.left': {
        left: 0,
        transform: 'translateX(-120px)',
        '@bp4':{
            transform: 'translateX(-73%) translateY(-55%) scale(0.75)'
        },
        '@bp5':{
            transform: 'translateX(-65%) translateY(-55%)'
        }
    },
    '.right': {
        right: 0,
        transform: 'translateX(120px)',
        '@bp4':{
            transform: 'translateX(67%) translateY(-55%) scale(0.75)'
        },
        '@bp5':{
            transform: 'translateX(65%) translateY(-55%)'
        }
    }
});